.post-web {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.post-mob {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.subHeading{
  font-size: small;
  line-height: small;
}