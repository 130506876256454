.jumbotron {
  margin-bottom: 0px;
}

.home-web {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.home-mob {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}