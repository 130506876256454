.row {
  margin: 10px 10px 10px;
}

.mob-post{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.web-post{
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.post-page{
  padding-top: 10px;
}